import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_purchase_form_new = _resolveComponent("purchase-form-new")

  return (_openBlock(), _createBlock(_component_purchase_form_new, {
    "order-details": _ctx.orderDetails,
    "order-state": _ctx.orderState,
    "show-complete-form-msg": _ctx.showCompleteFormMsg,
    "onUpdate:orderDetailItem": _ctx.xupdateOrderDetailItem,
    "onUpdate:orderQuantity": _ctx.xupdateOrderQuantity,
    onPurchase: _ctx.onPurchase
  }, null, 8, ["order-details", "order-state", "show-complete-form-msg", "onUpdate:orderDetailItem", "onUpdate:orderQuantity", "onPurchase"]))
}