
import { Form as VForm } from 'vee-validate';
import { defineComponent } from 'vue';
import InputField from '../InputField.vue';
import Box from '@/components/Box.vue';

const proptype = {
    type: String,
    default: '',
    required: true
};

const g = (field: string) => {
    return {
        // @ts-expect-error
        get() {
            // @ts-expect-error
            return this[field];
        },
        set(value: any) {
            // @ts-expect-error
            this.$emit(`update:${field}`, value);
        }
    };
};

export default defineComponent({
    name: 'OrderFormNew',
    components: {
        VForm,
        InputField,
        Box
    },
    props: {
        name: proptype,
        email: proptype
    },
    computed: {
        nameValue: g('name'),
        emailValue: g('email')
    },
    methods: {
        isRequired(value: any) {
            return value ? true : 'This field is required';
        },
        onSubmit() {}
    }
});
/*

*/
