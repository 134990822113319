
import { defineComponent } from 'vue';
import PurchaseFormNewOrderDetailsContainer from './PurchaseFormNewOrderDetailsContainer.vue';
import Box from '@/components/Box.vue';
import Quote from '@/components/Quote.vue';
import AmazonPurchase from '@/components/AmazonPurchase.vue';
import { mapActions } from 'vuex';
import OrderDetails from '@/Library/OrderDetails';
import OrderFormNewContainer from './OrderFormNewContainer.vue';
import OrderDetailItemType from '../../types/OrderDetailItem';
import PricingNew from './PricingNew.vue';

export default defineComponent({
    name: 'Purchase',
    components: {
        Quote,
        PurchaseFormNewOrderDetailsContainer,
        Box,
        AmazonPurchase,
        OrderFormNewContainer,
        PricingNew
    },
    props: {
        orderDetails: {
            type: OrderDetails,
            required: true
        },
        orderState: {
            type: Number,
            required: true
        },
        showCompleteFormMsg: {
            type: Boolean,
            required: true
        }
    },
    created() {
        this.resetPurchaseFormNew();
        this.showPaypalButtonsNew('#paypal-buttons');
    },
    destroyed() {
        this.resetPurchaseFormNew();
    },
    methods: {
        ...mapActions(['showPaypalButtonsNew', 'resetPurchaseFormNew']),
        updateOrderQuantity(quantity: number) {
            this.$emit('update:orderQuantity', quantity);
        },
        updateOrderDetailItem(orderDetailItem: OrderDetailItemType) {
            this.$emit('update:orderDetailItem', orderDetailItem);
        }
    }
});
