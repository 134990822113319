
import { Field as VField, ErrorMessage } from 'vee-validate';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'InputField',
    components: {
        VField,
        ErrorMessage
    },
    props: {
        modelValue: {
            required: true,
            type: String
        },
        name: {
            required: true,
            type: String
        },
        label: {
            required: true,
            type: String
        },
        inputtype: {
            type: String,
            default: 'text'
        }
    },
    methods: {
        isRequired(value: any) {
            return value ? true : 'This field is required';
        }
    }
});
