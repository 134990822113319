import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_purchase_form_new_order_details = _resolveComponent("purchase-form-new-order-details")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_purchase_form_new_order_details, {
      "order-details": _ctx.orderDetails,
      "items-to-order-options": _ctx.itemsToOrderOptions,
      "order-detail-item-options": _ctx.orderDetailItemOptions,
      "onUpdate:orderDetailItem": _ctx.updateOrderDetailItem,
      "onUpdate:orderQuantity": _ctx.updateOrderQuantity
    }, null, 8, ["order-details", "items-to-order-options", "order-detail-item-options", "onUpdate:orderDetailItem", "onUpdate:orderQuantity"])
  ]))
}