import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full text-indigo-400 bg-green-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_purchase_form_new_container = _resolveComponent("purchase-form-new-container")
  const _component_purchase_successful_new = _resolveComponent("purchase-successful-new")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.viewingState === _ctx.ViewingState.Form)
      ? (_openBlock(), _createBlock(_component_purchase_form_new_container, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.viewingState === _ctx.ViewingState.SuccessfulPurchase)
      ? (_openBlock(), _createBlock(_component_purchase_successful_new, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}