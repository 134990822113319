
import { defineComponent } from 'vue';
import RadioButtonGroup from '../RadioButtonGroup.vue';
import DedicationNew from './DedicationNew.vue';
import Box from '../Box.vue';
import BookId from '@/types/BookId';

export default defineComponent({
    name: 'OrderDetailItem',
    components: {
        RadioButtonGroup,
        Box,
        DedicationNew
    },
    props: {
        orderDetailItem: {
            type: Object,
            required: true
        },
        orderDetailItemOptions: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            // TODO the selected value is orderDetailItem.bookId
            selected: BookId.GoodGirlKarma
        };
    },
    computed: {
        // eslint-disable-next-line object-shorthand
        id: function(): string {
            return `book-${this.orderDetailItem.index}`;
        }
    },
    watch: {
        selected() {
            this.updateBookId();
        }
    },
    methods: {
        updateBookId() {
            this.update({ bookId: this.selected });
        },
        updateDedication(dedication: string) {
            this.update({ dedication });
        },
        update(replacement: Object) {
            this.$emit('update:orderDetailItem', { ...this.orderDetailItem, ...replacement });
        }
    }
});
