
import { defineComponent } from 'vue';

export default defineComponent({
    model: {
        event: 'change'
    },
    props: {
        id: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            required: true,
            type: [String, Number]
        },
        options: {
            type: [Array],
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        inputClass: {
            type: [String, Object],
            default: ''
        },
        horizontal: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        layoutClasses(): string {
            return this.horizontal ? 'flex flex-column flex-wrap' : '';
        }
    },
    methods: {
        updateValue(value: any) {
            this.$emit('update:modelValue', value);
        }
    }
});
