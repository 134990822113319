
import { defineComponent } from 'vue';
import OrderDetails from '@/Library/OrderDetails';
import OrderDetailItem from './OrderDetailItem.vue';
import RadioButtonGroup from '../RadioButtonGroup.vue';
import Box from '../Box.vue';
import OrderDetailItemType from '../../types/OrderDetailItem';
import { newPricing } from '@/Library/pricing';
import BookId from '@/types/BookId';

export default defineComponent({
    name: 'PurchaseFormNewOrderDetails',
    components: { Box, RadioButtonGroup, OrderDetailItem },
    props: {
        orderDetails: {
            type: OrderDetails,
            required: true
        },
        itemsToOrderOptions: {
            type: Array,
            required: true
        },
        orderDetailItemOptions: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            itemsToPurchase: 1
        };
    },
    computed: {
        treasuresGiftPrice(): string {
            return newPricing.bookPrice[BookId.TreasuresGift].priceNote;
        },
        goodGirlKarmPrice(): string {
            return newPricing.bookPrice[BookId.GoodGirlKarma].priceNote;
        }
    },
    methods: {
        updateOrderQuantity(quantity: number) {
            this.$emit('update:orderQuantity', quantity);
        },
        updateOderDetailItem(orderDetailItem: OrderDetailItemType) {
            this.$emit('update:orderDetailItem', orderDetailItem);
        }
    }
});
