import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mr-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_radio_button_group = _resolveComponent("radio-button-group")
  const _component_dedication_new = _resolveComponent("dedication-new")
  const _component_box = _resolveComponent("box")

  return (_openBlock(), _createBlock(_component_box, { class: "m-4 p-2  bg-white" }, {
    default: _withCtx(() => [
      _createVNode("div", null, "Book " + _toDisplayString(_ctx.orderDetailItem.index + 1), 1),
      _createVNode(_component_radio_button_group, {
        id: _ctx.id,
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selected = $event)),
        options: _ctx.orderDetailItemOptions
      }, {
        default: _withCtx((slotProps) => [
          _createVNode("div", _hoisted_1, _toDisplayString(slotProps.option.name), 1)
        ]),
        _: 1
      }, 8, ["id", "modelValue", "options"]),
      _createVNode(_component_dedication_new, {
        index: _ctx.orderDetailItem.index,
        "model-value": _ctx.orderDetailItem.dedication,
        "onUpdate:modelValue": _ctx.updateDedication
      }, null, 8, ["index", "model-value", "onUpdate:modelValue"])
    ]),
    _: 1
  }))
}