
import { defineComponent } from 'vue';
import PurchaseFormNew from './PurchaseFormNew.vue';
import { mapGetters, mapActions } from 'vuex';
import OrderDetailItem from '@/types/OrderDetailItem';

export default defineComponent({
    name: 'PurchaseFormNewContainer',
    components: {
        PurchaseFormNew
    },
    computed: {
        ...mapGetters(['orderDetails', 'orderState', 'showCompleteFormMsg'])
    },
    created() {
        this.resetPurchaseFormNew();
    },
    destroyed() {
        this.resetPurchaseFormNew();
    },
    methods: {
        xupdateOrderQuantity(quantity: number) {
            this.updateOrderQuantity(quantity);
        },
        xupdateOrderDetailItem(orderDetailItem: OrderDetailItem) {
            this.updateOrderDetailItem(orderDetailItem);
        },
        ...mapActions([
            'resetPurchaseFormNew',
            'showPaypalButtons',
            'updateOrderQuantity',
            'updateOrderDetailItem',
            'purchaseNew'
        ]),

        onPurchase() {
            this.purchaseNew();
        }
    }
});
