import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "block m-2" }
const _hoisted_2 = {
  for: "name",
  class: "text-gray-700"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_field = _resolveComponent("v-field")
  const _component_error_message = _resolveComponent("error-message")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("label", _hoisted_1, [
      _createVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1),
      _createVNode(_component_v_field, {
        name: _ctx.name,
        type: _ctx.inputtype,
        class: "block w-full border-2 border-black border-solid resize-y",
        value: _ctx.modelValue,
        rules: _ctx.isRequired,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
      }, null, 8, ["name", "type", "value", "rules"]),
      _createVNode(_component_error_message, {
        class: "text-sm text-red-600",
        name: "name"
      })
    ])
  ]))
}