<template>
    <div class="hidden mx-auto">
        <a
            href="https://www.amazon.com/Treasures-Gift-Laurie-Hanson/dp/1592986234"
            target="_blank"
            rel="noopener noreferrer"
            style="border:none;text-decoration:none"
            ><img src="@assets/amazon-button2.png"
        /></a>
    </div>
</template>

<script>
export default {
    name: 'AmazonPurchase'
};
</script>
