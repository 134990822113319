
import { defineComponent } from 'vue';
import Group from '@/components/Group.vue';
import Item from '@/components/Item.vue';
import { mapGetters } from 'vuex';
import { formatPrice, newPricing } from '@/Library/pricing';

export default defineComponent({
    name: 'OrderSummaryNew',
    components: {
        Group,
        Item
    },
    computed: {
        ...mapGetters(['orderDetails', 'shippingBillId']),
        price() {
            // @ts-expect-error
            return formatPrice(newPricing.total(this.orderDetails));
        }
    }
});
