
import { defineComponent } from 'vue';
import PurchaseSuccessfulNew from '@/components/purchasenew/PurchaseSuccessfulNew.vue';
import PurchaseFormNewContainer from '@/components/purchasenew/PurchaseFormNewContainer.vue';
import { mapGetters, mapActions } from 'vuex';
import { ViewingState } from '@/store/index';

export default defineComponent({
    name: 'PurchaseNew',
    components: {
        PurchaseFormNewContainer,
        PurchaseSuccessfulNew
    },
    data() {
        return {
            ViewingState
        };
    },
    computed: {
        ...mapGetters(['viewingState'])
    },
    created() {
        // this.viewingPurchaseForm();
    },
    methods: {
        ...mapActions(['viewingPurchaseForm'])
    }
});
