import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_box = _resolveComponent("box")

  return (_openBlock(), _createBlock(_component_box, { class: "m-4 p-2 bg-gray-100  text-black" }, {
    default: _withCtx(() => [
      _createVNode("div", null, "Order Amount: $" + _toDisplayString(_ctx.totalBookPrice), 1),
      _createVNode("div", null, "Shipping: $" + _toDisplayString(_ctx.totalShipping), 1),
      _createVNode("div", null, "Total: $" + _toDisplayString(_ctx.total), 1)
    ]),
    _: 1
  }))
}