import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "mr-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_radio_button_group = _resolveComponent("radio-button-group")
  const _component_order_detail_item = _resolveComponent("order-detail-item")
  const _component_box = _resolveComponent("box")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_box, { class: "m-4 p-2  bg-gray-200" }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          _createVNode("div", null, _toDisplayString(_ctx.goodGirlKarmPrice), 1),
          _createVNode("div", null, _toDisplayString(_ctx.treasuresGiftPrice), 1)
        ]),
        _createVNode(_component_radio_button_group, {
          id: "radio",
          modelValue: _ctx.itemsToPurchase,
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = ($event: any) => (_ctx.itemsToPurchase = $event)),
            _ctx.updateOrderQuantity
          ],
          options: _ctx.itemsToOrderOptions,
          horizontal: true,
          label: "Number of books to purchase:"
        }, {
          default: _withCtx((slotProps) => [
            _createVNode("div", _hoisted_2, _toDisplayString(slotProps.option.value), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "options", "onUpdate:modelValue"]),
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.itemsToPurchase, (item) => {
          return (_openBlock(), _createBlock("div", { key: item }, [
            _createVNode(_component_order_detail_item, {
              "order-detail-item": _ctx.orderDetails.bookDetails[item - 1],
              "order-detail-item-options": _ctx.orderDetailItemOptions,
              "onUpdate:orderDetailItem": _ctx.updateOderDetailItem
            }, null, 8, ["order-detail-item", "order-detail-item-options", "onUpdate:orderDetailItem"])
          ]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}