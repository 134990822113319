import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col lg:flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_group = _resolveComponent("group")
  const _component_order_summary_new = _resolveComponent("order-summary-new")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_group, { label: "Thank you  for your purchase!" }, {
      default: _withCtx(() => [
        _createVNode("span", null, "I'll mail out your " + _toDisplayString(_ctx.orderDetails.books === 1 ? 'book' : 'books') + " soon.", 1)
      ]),
      _: 1
    }),
    _createVNode(_component_order_summary_new)
  ]))
}