import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_order_form_new = _resolveComponent("order-form-new")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_order_form_new, {
      name: _ctx.name,
      "onUpdate:name": _cache[1] || (_cache[1] = ($event: any) => (_ctx.name = $event)),
      email: _ctx.email,
      "onUpdate:email": _cache[2] || (_cache[2] = ($event: any) => (_ctx.email = $event))
    }, null, 8, ["name", "email"])
  ]))
}