
import { defineComponent } from 'vue';
import Group from '@/components/Group.vue';
import OrderSummaryNew from '@/components/purchasenew/OrderSummaryNew.vue';

import { mapGetters, mapActions } from 'vuex';

export default defineComponent({
    name: 'Purchase',
    components: {
        Group,
        OrderSummaryNew
    },
    computed: {
        ...mapGetters(['orderDetails'])
    },
    methods: {
        ...mapActions([])
    }
});
